<app-header-two></app-header-two>

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <br />
        <br />

        <div class="position-relative mt-3 mb-5 row justify-content-center ">
            <div
                class="position-absolute top-0 start-50 translate-middle row justify-content-center"
            >
                <div class="col-sm-8 col-md-9  top-space-responsive">
                    <form class="d-flex mb-5 mt-5" role="search">
                        <input
                            class="form-control me-2 rounded-pill input1 border border-info"
                            type="search"
                            placeholder="Search For The Deases"
                            aria-label="Search"
                        />
                        <button
                            class="btn b1 rounded-pill col-lg-3"
                            type="submit"
                            style="background-color: #12b6df; color: #ffffff"
                        >
                            <span class="s1">SEARCH</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-lg-8 form-control me-2 rounded-pill input1 text-center">
                <input
                    placeholder="Search FOR The Disease"
                    class="search-inp"
                />
            </div>
            <div class="col-lg-4">
                <div class="destinations-btn-box text-left">
                    <a class="default-btn">SEARCH</a>
                </div>
            </div>
        </div> -->

        <br />
        <br />

        <div class="row">
            <div
                class="col-lg-3 col-md-4 col-sm-6"
                *ngFor="let category of categories"
            >
                <div class="partner-item">
                    <mat-card
                        class="shadow p-3 mb-5 bg-white rounded"
                        (click)="navigateToViewDisease(category)"
                    >
                        <img
                            [src]="category.image.url"
                            alt="image"
                            class="card-img-top img-responsive center-block d-block mx-auto mt-4"
                        />

                        <mat-card-content>
                            <p class="library-name">
                                <span>{{ category.title }}</span>
                            </p>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</section>
