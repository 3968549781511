<app-header-two></app-header-two>

<section class="listing-categories-area pt-100 pb-70">
    <div>
        <div class="row">
            <div class="col-lg-3 col-md-12 top-space-card">
                <mat-card class="user-card">
                    <div class="col-flex">
                        <div class="text-center" style="flex: 1">
                            <img src="assets/img/avatar.jpg" />
                        </div>
                        <div class="text-center" style="flex: 1">
                            <p class="user-name">{{ medicalFile.name }}</p>
                        </div>
                        <div class="text-center" style="flex: 1">
                            <p class="email-name">{{ medicalFile.email }}</p>
                        </div>
                        <div
                            class="text-center subscribe-container"
                            style="flex: 1"
                        >
                            <p class="subscribe-now">
                                {{'You Are Not Currently Subscribed To The Service
                                Subscribe Now' | translate}}
                            </p>
                            <div class="destinations-btn-box">
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="default-btn"
                                    routerLink="/subscribe"
                                >
                                    {{'Subscribe Now' | translate}}
                                </button>
                            </div>
                        </div>

                        <div style="flex: 1" class="text-center">
                            <mat-list>
                                <mat-list-item
                                    class="list-item"
                                    (click)="changeView('medicalFile')"
                                >
                                    <h4 mat-line>{{'Medical File' | translate}}</h4>
                                    <mat-icon *ngIf="lang == 'ar'"
                                        >chevron_left</mat-icon
                                    >
                                    <mat-icon *ngIf="lang == 'en'"
                                        >chevron_right</mat-icon
                                    >
                                </mat-list-item>
                                <mat-list-item
                                    class="list-item"
                                    (click)="changeView('medicalConsultation')"
                                >
                                    <h4 mat-line>{{'Medical Consultations' | translate}}</h4>
                                    <mat-icon *ngIf="lang == 'ar'"
                                        >chevron_left</mat-icon
                                    >
                                    <mat-icon *ngIf="lang == 'en'"
                                        >chevron_right</mat-icon
                                    >
                                </mat-list-item>
                                <mat-list-item
                                    class="list-item"
                                    (click)="changeView('sessions')"
                                >
                                    <h4 mat-line>{{'Sessions' | translate}}</h4>
                                    <mat-icon *ngIf="lang == 'ar'"
                                        >chevron_left</mat-icon
                                    >
                                    <mat-icon *ngIf="lang == 'en'"
                                        >chevron_right</mat-icon
                                    >
                                </mat-list-item>

                                <mat-list-item
                                    class="list-item"
                                    (click)="changeView('paymentHistory')"
                                >
                                    <h4 mat-line>{{'Payment History' | translate}}</h4>
                                    <mat-icon *ngIf="lang == 'ar'"
                                        >chevron_left</mat-icon
                                    >
                                    <mat-icon *ngIf="lang == 'en'"
                                        >chevron_right</mat-icon
                                    >
                                </mat-list-item>

                                <mat-list-item
                                    class="list-item"
                                    (click)="changeView('wallet')"
                                >
                                    <h4 mat-line>{{'Wallet' | translate}}</h4>
                                    <mat-icon *ngIf="lang == 'ar'"
                                        >chevron_left</mat-icon
                                    >
                                    <mat-icon *ngIf="lang == 'en'"
                                        >chevron_right</mat-icon
                                    >
                                </mat-list-item>

                                <mat-list-item
                                    class="list-item"
                                    (click)="changeView('settings')"
                                >
                                    <h4 mat-line>{{'Settings' | translate}}</h4>
                                    <mat-icon *ngIf="lang == 'ar'"
                                        >chevron_left</mat-icon
                                    >
                                    <mat-icon *ngIf="lang == 'en'"
                                        >chevron_right</mat-icon
                                    >
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="col-lg-8 col-md-12" *ngIf="isMedicalFile">
                <div class="title">
                    <h2>{{ "Medical File" | translate }}</h2>
                </div>
                <mat-card class="ask-card">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="billing-details">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label
                                                    >{{
                                                        "Full Name" | translate
                                                    }}
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Text"
                                                    name="txtName"
                                                    [(ngModel)]="
                                                        medicalFile.name
                                                    "
                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group mb-3">
                                                <label
                                                    >{{
                                                        "Date Of Birth"
                                                            | translate
                                                    }}
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <mat-form-field>
                                                    <input
                                                        matInput
                                                        [matDatepicker]="
                                                            pickerEnd
                                                        "
                                                        name="txtDateOfBirth"
                                                        class="form-control birthdate"
                                                        name="txtDateOfBirth"
                                                        [(ngModel)]="
                                                            medicalFile.dob
                                                        "
                                                    />
                                                    <input type="hidden" />
                                                    <mat-datepicker-toggle
                                                        matSuffix
                                                        [for]="pickerEnd"
                                                    ></mat-datepicker-toggle>
                                                    <mat-datepicker
                                                        #pickerEnd
                                                    ></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label
                                                    >{{ "Email" | translate }}
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    
                                                    name="txtEmail"
                                                    [(ngModel)]="
                                                        medicalFile.email
                                                    "
                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label
                                                    >{{
                                                        "Mobile"
                                                            | translate
                                                    }}
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                 
                                                    style="direction: inherit !important;"
                                                    name="txtPhone"
                                                    [(ngModel)]="
                                                        medicalFile.phone
                                                    "
                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label
                                                    >{{ "Height" | translate }}
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                   
                                                    name="txtHeight"
                                                    [(ngModel)]="
                                                        medicalFile.height
                                                    "
                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label
                                                    >{{ "Width" | translate }}
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                   
                                                    name="txtWidth"
                                                    [(ngModel)]="
                                                        medicalFile.width
                                                    "
                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                          <div class="form-group">
                                            <label
                                            >{{ "Medical Notes" | translate }}
                                            <span class="required"
                                                >*</span
                                            ></label
                                        >
                                            <textarea
                                            type="text"
                                            class="form-control"
                                            [placeholder]="'Medical Notes' | translate"
                                            name="txtNotes"
                                            [(ngModel)]="medicalFile.medical_notes"
                                        ></textarea>
                                          </div>
                                        </div>

                                        <div
                                            class="col-lg-12 text-center top-space"
                                        >
                                            <div class="destinations-btn-box">
                                                <button
                                                    mat-raised-button
                                                    color="primary"
                                                    class="default-btn cancel-btn"
                                                    (click)="resetMedicalFile()"
                                                >
                                                    {{ "Cancel" | translate }}
                                                </button>

                                                <button
                                                    mat-raised-button
                                                    color="primary"
                                                    class="default-btn"
                                                    (click)="
                                                        updateMedicalFile()
                                                    "
                                                >
                                                    {{ "Save" | translate }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>

            <div class="col-lg-4 col-md-12" *ngIf="isWallet">
                <div class="title">
                    <h2>{{ "Wallet" | translate }}</h2>
                </div>
                <mat-card class="ask-card wallet-card">
                    <img
                        class="wallet-img"
                        src="https://material.angular.io/assets/img/examples/shiba1.jpg"
                    />
                    <p class="wallet-text">
                        You Have <span class="wallet-amount">200$</span> In Your
                        Wallet
                    </p>
                </mat-card>
                <div class="destinations-btn-box wallet-btn">
                    <button
                        mat-raised-button
                        color="primary"
                        class="default-btn"
                        routerLink="/subscribe"
                    >
                        Add Credit
                    </button>
                </div>
            </div>

            <div class="col-lg-4" *ngIf="isWallet"></div>

            <div class="col-lg-8 col-md-12" *ngIf="isSessions">
                <mat-tab-group mat-align-tabs="start">
                    <mat-tab [label]="'Upcoming Sessions' | translate">
                        <mat-card
                            class="ask-card session-card"
                            *ngFor="let session of upcomingSessions"
                        >
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="row-flex">
                                        <div class="pic-container">
                                            <img
                                                class="doctor-pic"
                                                [src]="
                                                    session?.doctor?.pic?.url
                                                        ? session.doctor.pic.url
                                                        : 'https://material.angular.io/assets/img/examples/shiba1.jpg'
                                                "
                                            />
                                        </div>
                                        <div class="col-flex doc-info">
                                            <p class="doc-name">
                                                {{ session.doctor.name }}
                                            </p>
                                            <p class="doc-specialty">
                                                {{
                                                    session.doctor
                                                        .specialized_treatment
                                                }}
                                            </p>
                                            <div class="star-rating">
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                            </div>
                                            <p class="session-date">
                                                {{ session.date }}
                                                {{ session.time }}-pm
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div
                                        class="col-flex"
                                        style="white-space: nowrap"
                                    >
                                        <div class="row-flex">
                                            <div style="flex: 1">
                                                <mat-icon>videocam</mat-icon>
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div class="video-call">
                                                <span>Video Call</span>
                                            </div>
                                            <div style="flex: 1"></div>
                                        </div>
                                        <div class="row-flex">
                                            <div style="flex: 1">
                                                <mat-icon>watch_later</mat-icon>
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div class="video-call">
                                                <span
                                                    >{{ session.duration }}-Min
                                                    Session With</span
                                                >
                                            </div>
                                            <div style="flex: 1"></div>
                                        </div>

                                        <div class="row-flex">
                                            <div style="flex: 1">
                                                <mat-icon
                                                    >perm_identity</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div class="video-call">
                                                <span>{{
                                                    session.doctor.name
                                                }}</span>
                                            </div>
                                            <div style="flex: 1"></div>
                                        </div>

                                        <div class="row-flex">
                                            <div style="flex: 1">
                                                <mat-icon>credit_card</mat-icon>
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div class="video-call">
                                                <span
                                                    >Saved Card #
                                                    ***********1234</span
                                                >
                                            </div>
                                            <div style="flex: 1"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4" style="text-align: end">
                                    <span
                                        class="reschedule"
                                        (click)="rescheduleSession()"
                                        >Reschedule</span
                                    >
                                </div>
                            </div>
                        </mat-card>
                    </mat-tab>
                    <mat-tab [label]="'Past Sessions' | translate">
                        <mat-card
                            class="ask-card session-card"
                            *ngFor="let session of pastSessions"
                        >
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="row-flex">
                                        <div class="pic-container">
                                            <img
                                                class="doctor-pic"
                                                [src]="
                                                    session?.doctor?.pic?.url
                                                        ? session.doctor.pic.url
                                                        : 'https://material.angular.io/assets/img/examples/shiba1.jpg'
                                                "
                                            />
                                        </div>
                                        <div class="col-flex doc-info">
                                            <p class="doc-name">
                                                {{ session.doctor.name }}
                                            </p>
                                            <p class="doc-specialty">
                                                {{
                                                    session.doctor
                                                        .specialized_treatment
                                                }}
                                            </p>
                                            <div class="star-rating">
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                            </div>
                                            <p class="session-date">
                                                {{ session.date }}
                                                {{ session.time }}-pm
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div
                                        class="col-flex"
                                        style="white-space: nowrap"
                                    >
                                        <div class="row-flex">
                                            <div style="flex: 1">
                                                <mat-icon>videocam</mat-icon>
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div class="video-call">
                                                <span>Video Call</span>
                                            </div>
                                            <div style="flex: 1"></div>
                                        </div>
                                        <div class="row-flex">
                                            <div style="flex: 1">
                                                <mat-icon>watch_later</mat-icon>
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div class="video-call">
                                                <span
                                                    >{{ session.duration }}-Min
                                                    Session With</span
                                                >
                                            </div>
                                            <div style="flex: 1"></div>
                                        </div>

                                        <div class="row-flex">
                                            <div style="flex: 1">
                                                <mat-icon
                                                    >perm_identity</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div class="video-call">
                                                <span>{{
                                                    session.doctor.name
                                                }}</span>
                                            </div>
                                            <div style="flex: 1"></div>
                                        </div>

                                        <div class="row-flex">
                                            <div style="flex: 1">
                                                <mat-icon>credit_card</mat-icon>
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div class="video-call">
                                                <span
                                                    >Saved Card #
                                                    ***********1234</span
                                                >
                                            </div>
                                            <div style="flex: 1"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4" style="text-align: end">
                                    <span
                                        class="reschedule"
                                        (click)="rescheduleSession()"
                                        >Reschedule</span
                                    >
                                </div>
                            </div>
                        </mat-card></mat-tab
                    >
                    <mat-tab [label]="'Cancelled Sessions' | translate">
                        <mat-card
                            class="ask-card session-card"
                            *ngFor="let session of cancelledSessions"
                        >
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="row-flex">
                                        <div class="pic-container">
                                            <img
                                                class="doctor-pic"
                                                [src]="
                                                    session?.doctor?.pic?.url
                                                        ? session.doctor.pic.url
                                                        : 'https://material.angular.io/assets/img/examples/shiba1.jpg'
                                                "
                                            />
                                        </div>
                                        <div class="col-flex doc-info">
                                            <p class="doc-name">
                                                {{ session.doctor.name }}
                                            </p>
                                            <p class="doc-specialty">
                                                {{
                                                    session.doctor
                                                        .specialized_treatment
                                                }}
                                            </p>
                                            <div class="star-rating">
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                            </div>
                                            <p class="session-date">
                                                {{ session.date }}
                                                {{ session.time }}-pm
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div
                                        class="col-flex"
                                        style="white-space: nowrap"
                                    >
                                        <div class="row-flex">
                                            <div style="flex: 1">
                                                <mat-icon>videocam</mat-icon>
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div class="video-call">
                                                <span>Video Call</span>
                                            </div>
                                            <div style="flex: 1"></div>
                                        </div>
                                        <div class="row-flex">
                                            <div style="flex: 1">
                                                <mat-icon>watch_later</mat-icon>
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div class="video-call">
                                                <span
                                                    >{{ session.duration }}-Min
                                                    Session With</span
                                                >
                                            </div>
                                            <div style="flex: 1"></div>
                                        </div>

                                        <div class="row-flex">
                                            <div style="flex: 1">
                                                <mat-icon
                                                    >perm_identity</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div class="video-call">
                                                <span>{{
                                                    session.doctor.name
                                                }}</span>
                                            </div>
                                            <div style="flex: 1"></div>
                                        </div>

                                        <div class="row-flex">
                                            <div style="flex: 1">
                                                <mat-icon>credit_card</mat-icon>
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div class="video-call">
                                                <span
                                                    >Saved Card #
                                                    ***********1234</span
                                                >
                                            </div>
                                            <div style="flex: 1"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4" style="text-align: end">
                                    <span
                                        class="reschedule"
                                        (click)="rescheduleSession()"
                                        >Reschedule</span
                                    >
                                </div>
                            </div>
                        </mat-card>
                    </mat-tab>
                </mat-tab-group>

                <div class="destinations-btn-box">
                    <button
                        mat-raised-button
                        color="primary"
                        class="default-btn"
                        routerLink="/doctor-list"
                    >
                        Book New Session
                    </button>
                </div>
            </div>

            <div class="col-lg-8 col-md-12" *ngIf="isPaymentHistory">
                <div class="title">
                    <h2>{{ "Payment History" | translate }}</h2>
                </div>

                <div class="row">
                    <div class="col-lg-4">
                        <mat-card class="payment-history-card">
                            <mat-card-header>
                                <div
                                    mat-card-avatar
                                    class="example-header-image"
                                ></div>
                                <mat-card-subtitle>
                                    Session With</mat-card-subtitle
                                >
                                <mat-card-title>Heba A. Mahmoud</mat-card-title>
                                <div class="payment-cost">
                                    <span>50$</span>
                                </div>
                            </mat-card-header>

                            <mat-card-content>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >calendar_month</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p>Date</p>
                                                <p style="margin-top: -20px">
                                                    15 July 2022
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >schedule</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p>Time</p>
                                                <p style="margin-top: -20px">
                                                    15 July 2022
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="top-space"></div>
                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >credit_card</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p>Card Number</p>
                                                <p style="margin-top: -20px">
                                                    23654874********
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >event_available</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p>Session</p>
                                                <p style="margin-top: -20px">
                                                    Cancelled
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="top-space"></div>

                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >refresh</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p style="white-space: nowrap">
                                                    Refund To Wallet
                                                </p>
                                                <p style="margin-top: -20px">
                                                    Yes
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-lg-4">
                        <mat-card class="payment-history-card">
                            <mat-card-header>
                                <div
                                    mat-card-avatar
                                    class="example-header-image"
                                ></div>
                                <mat-card-subtitle>
                                    Session With</mat-card-subtitle
                                >
                                <mat-card-title>Heba A. Mahmoud</mat-card-title>
                                <div class="payment-cost">
                                    <span>50$</span>
                                </div>
                            </mat-card-header>

                            <mat-card-content>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >calendar_month</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p>Date</p>
                                                <p style="margin-top: -20px">
                                                    15 July 2022
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >schedule</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p>Time</p>
                                                <p style="margin-top: -20px">
                                                    15 July 2022
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="top-space"></div>
                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >credit_card</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p>Card Number</p>
                                                <p style="margin-top: -20px">
                                                    23654874********
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >event_available</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p>Session</p>
                                                <p style="margin-top: -20px">
                                                    Cancelled
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="top-space"></div>

                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >refresh</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p style="white-space: nowrap">
                                                    Refund To Wallet
                                                </p>
                                                <p style="margin-top: -20px">
                                                    Yes
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-lg-4">
                        <mat-card class="payment-history-card">
                            <mat-card-header>
                                <div
                                    mat-card-avatar
                                    class="example-header-image"
                                ></div>
                                <mat-card-subtitle>
                                    Session With</mat-card-subtitle
                                >
                                <mat-card-title>Heba A. Mahmoud</mat-card-title>
                                <div class="payment-cost">
                                    <span>50$</span>
                                </div>
                            </mat-card-header>

                            <mat-card-content>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >calendar_month</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p>Date</p>
                                                <p style="margin-top: -20px">
                                                    15 July 2022
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >schedule</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p>Time</p>
                                                <p style="margin-top: -20px">
                                                    15 July 2022
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="top-space"></div>
                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >credit_card</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p>Card Number</p>
                                                <p style="margin-top: -20px">
                                                    23654874********
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >event_available</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p>Session</p>
                                                <p style="margin-top: -20px">
                                                    Cancelled
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="top-space"></div>

                                    <div class="col-lg-6">
                                        <div class="row-flex">
                                            <div
                                                style="
                                                    margin-right: 7px;
                                                    margin-left: 7px;
                                                "
                                            >
                                                <mat-icon
                                                    style="margin-top: -10px"
                                                    >refresh</mat-icon
                                                >
                                            </div>
                                            <div style="flex: 1">
                                                <p style="white-space: nowrap">
                                                    Refund To Wallet
                                                </p>
                                                <p style="margin-top: -20px">
                                                    Yes
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12" *ngIf="isMedicalConsultation">
                <div class="products-details-desc">
                    <h3 class="collapse-header-name">{{'Medical Consultations' | translate}}</h3>

                    <div class="row">
                        <div
                            class="col-lg-6"
                            *ngFor="let question of questions"
                        >
                            <mat-card class="consultation-card">
                                <div class="col-flex">
                                    <div style="flex: 1; margin-bottom: 15px; ">
                                        <p
                                            class="consultation-question"
                                            *ngIf="lang == 'ar'"
                                        >
                                            ?{{ question.title }}
                                        </p>

                                        <p
                                            class="consultation-question"
                                            *ngIf="lang == 'en'"
                                        >
                                            {{ question.title }}?
                                            
                                        </p>
                                    </div>

                                    <div style="flex: 1; margin-top: 15px">
                                        <p class="consultation-answer" >
                                           {{question.text}}
                                        </p>
                                      
                                    </div>
                                </div>
                            </mat-card>
                        </div>

                        <div class="col-lg-4"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12" *ngIf="isSettings">
                <div class="products-details-desc">
                    <h3 class="collapse-header-name">{{'Settings' | translate}}</h3>

                    <mat-card class="ask-card setting-card">
                        <div class="col-flex">
                            <div class="row-flex setting-card-row">
                                <div style="flex: 1; margin-top: -5px">
                                    <span class="notification-toggle-text"
                                        >{{'Notifications' | translate}}</span
                                    >
                                </div>
                                <div style="flex: 10"></div>
                                <div style="flex: 1; text-align: end">
                                    <mat-slide-toggle
                                        color="primary"
                                        name="txtNotification"
                                        [(ngModel)]="allowNotification"
                                        (onChange)="
                                            setNotificationPermission($event)
                                        "
                                    ></mat-slide-toggle>
                                </div>
                            </div>
                            <div class="row-flex setting-card-row">
                                <div style="flex: 1; margin-top: -5px">
                                    <span class="notification-toggle-text"
                                        >{{'Auto Renewal Subcribe' | translate}}</span
                                    >
                                </div>
                                <div style="flex: 10"></div>
                                <div style="flex: 1; text-align: end">
                                    <mat-slide-toggle
                                        color="primary"
                                        name="txtAutoRenewal"
                                        [(ngModel)]="autoRenewal"
                                        (onChange)="setAutoRenewal($event)"
                                    ></mat-slide-toggle>
                                </div>
                            </div>
                            <div class="row-flex setting-card-row">
                                <div style="flex: 1; margin-top: -5px">
                                    <span class="notification-toggle-text"
                                        >{{'Language' | translate}}</span
                                    >
                                </div>
                                <div style="flex: 10"></div>
                                <div
                                    style="
                                        flex: 1;
                                        text-align: end;
                                        color: #707070 !important;
                                    "
                                >
                                    <select
                                        name="txtLang"
                                        [(ngModel)]="selectedLang"
                                    >
                                        <option value="en">{{'English' | translate}}</option>
                                        <option value="ar">{{'Arabic' | translate}}</option>
                                    </select>
                                </div>
                            </div>      
                            <div class="row-flex setting-card-row">
                                <div style="flex: 1; margin-top: -5px">
                                    <span class="notification-toggle-text"
                                        >{{'Change Email' | translate}}</span
                                    >
                                </div>
                                <div style="flex: 10"></div>
                                <div style="flex: 1; text-align: end">
                                    <button
                                        mat-stroked-button
                                        style="color: #707070 !important"
                                        (click)="changeEmail()"
                                    >
                                        {{'Change' | translate}}
                                    </button>
                                </div>
                            </div>
                            <div class="row-flex setting-card-row">
                                <div style="flex: 1; margin-top: -5px">
                                    <span class="notification-toggle-text"
                                        >{{'Change Password' | translate}}</span
                                    >
                                </div>
                                <div style="flex: 10"></div>
                                <div style="flex: 1; text-align: end">
                                    <button
                                        mat-stroked-button
                                        style="color: #707070 !important"
                                        (click)="changePassword()"
                                    >
                                        {{'Change' | translate}}
                                    </button>
                                </div>
                            </div>
                            <div class="row-flex setting-card-row">
                                <div style="flex: 1; margin-top: -5px">
                                    <span class="notification-toggle-text"
                                        >{{'Phone Number' | translate}}</span
                                    >
                                </div>
                                <div style="flex: 10"></div>
                                <div style="flex: 1; text-align: end">
                                    <button
                                        mat-stroked-button
                                        style="color: #707070 !important"
                                        (click)="changePhoneNumber()"
                                    >
                                        {{'Change' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="destinations-btn-box">
                            <button
                                mat-raised-button
                                color="primary"
                                class="default-btn setting-btn"
                            >
                                {{'Save' | translate}}
                            </button>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="col-lg-2" *ngIf="isSettings"></div>
        </div>
    </div>
</section>
