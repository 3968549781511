import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class ApiService {
    constructor(private httpClient: HttpClient) {}

    getNews(currentPage?: number) {
        return this.httpClient.get(
            `${environment.baseUrl}` +
                "/news?" +
                `lang=${
                    localStorage.getItem("lang")
                        ? localStorage.getItem("lang")
                        : "ar"
                }` +
                (currentPage ? "&page=" + `${currentPage}` : "")
        );
    }

    getSubNews(catID: Number, currentPage?: number) {
        console.log(catID);
        if (!catID) {
            return this.httpClient.get(
                `${environment.baseUrl}` +
                    "/news?" +
                    `lang=${
                        localStorage.getItem("lang")
                            ? localStorage.getItem("lang")
                            : "ar"
                    }` +
                    (currentPage ? "&page=" + `${currentPage}` : "")
            );
        }
        return this.httpClient.get(
            `${environment.baseUrl}` +
                "/news?" +
                `lang=${
                    localStorage.getItem("lang")
                        ? localStorage.getItem("lang")
                        : "ar"
                }` +
                "&category_id=" +
                `${catID}` +
                (currentPage ? "&page=" + `${currentPage}` : "")
        );
    }

    getPrescriptions() {
        let id: number = 6;
        return this.httpClient.get(
            `${environment.baseUrl}` +
                "/news?" +
                `lang=${
                    localStorage.getItem("lang")
                        ? localStorage.getItem("lang")
                        : "ar"
                }` +
                "&category_id=" +
                `${id}`
        );
    }

    getDiseaseCategories() {
        return this.httpClient.get(
            `${environment.baseUrl}` +
                "/disease-categories?" +
                `lang=${
                    localStorage.getItem("lang")
                        ? localStorage.getItem("lang")
                        : "ar"
                }`
        );
    }

    getNewsCategories() {
        return this.httpClient.get(
            `${environment.baseUrl}` +
                "/news-categories?" +
                `lang=${
                    localStorage.getItem("lang")
                        ? localStorage.getItem("lang")
                        : "ar"
                }`
        );
    }

    

    getWellnessInitiatives() {
        let id: number = 2;
        return this.httpClient.get(
            `${environment.baseUrl}` +
                "/news?" +
                `lang=${
                    localStorage.getItem("lang")
                        ? localStorage.getItem("lang")
                        : "ar"
                }` +
                "&category_id=" +
                `${id}`
        );
    }

    getQuestions() {
        return this.httpClient.get(`${environment.baseUrl}` + "/questions");
    }

    getMyQuestions() {
        return this.httpClient.get(`${environment.baseUrl}` + "/my-questions");
    }

    getDoctors() {
        return this.httpClient.get(
            `${environment.baseUrl}` +
                "/doctors?" +
                `lang=${
                    localStorage.getItem("lang")
                        ? localStorage.getItem("lang")
                        : "ar"
                }`
        );
    }

    updateProfile(user, dob) {
        user.dob = dob;
        let medicalFile: any = {};
        medicalFile.name = user.name;
        medicalFile.dob = user.dob;
        medicalFile.email = user.email;
        medicalFile.height = user.height;
        medicalFile.width = user.width;
        medicalFile.phone = user.phone;
        medicalFile.medical_notes = user.medical_notes;
        return this.httpClient.post(
            `${environment.baseUrl}` + "/profile",
            medicalFile
        );
    }

    updatePatientProperty(prop) {
        return this.httpClient.post(
            `${environment.baseUrl}` + "/profile",
            prop
        );
    }

    getPatientSessions() {
        return this.httpClient.get(`${environment.baseUrl}` + "/sessions");
    }

    getSingleNew(newID) {
        let url =
            `${environment.baseUrl}` +
            "/news/" +
            `${newID}` +
            "?" +
            `lang=${
                localStorage.getItem("lang")
                    ? localStorage.getItem("lang")
                    : "ar"
            }`;
        return this.httpClient.get(url);
    }

    getHealthPrescription(prescriptionID) {
        let url =
            `${environment.baseUrl}` +
            "/prescriptions/" +
            `${prescriptionID}` +
            "?" +
            `lang=${
                localStorage.getItem("lang")
                    ? localStorage.getItem("lang")
                    : "ar"
            }`;
        return this.httpClient.get(url);
    }

    getDiseaseByID(id) {
        let url =
            `${environment.baseUrl}` +
            "/diseases/" +
            `${id}` +
            "?" +
            `lang=${
                localStorage.getItem("lang")
                    ? localStorage.getItem("lang")
                    : "ar"
            }`;
        return this.httpClient.get(url);
    }

    getDiseasesByID(id) {
        let url =
            `${environment.baseUrl}` +
            "/diseases/" +
            "?" +
            `lang=${
                localStorage.getItem("lang")
                    ? localStorage.getItem("lang")
                    : "ar"
            }` +
            "&cateogry_id=" +
            `${id}`;
        return this.httpClient.get(url);
    }

    getInitiativeByID(id) {
        let url =
            `${environment.baseUrl}` +
            "/wellness-init/" +
            `${id}` +
            "?" +
            `lang=${
                localStorage.getItem("lang")
                    ? localStorage.getItem("lang")
                    : "ar"
            }`;
        return this.httpClient.get(url);
    }

    postQuestion(question) {
        let formData: FormData = new FormData();
        formData.append("title", question.title);
        formData.append("text", question.text);
        formData.append("field_id", question.field_id);
        if (question.files && question.files.lenght) {
            formData.append("files", question.files);
        }
        return this.httpClient.post(
            `${environment.baseUrl}` + "/questions",
            formData
        );
    }

    getMedicalFields() {
        return this.httpClient.get(
            `${environment.baseUrl}` +
                "/medical-fields?" +
                `lang=${
                    localStorage.getItem("lang")
                        ? localStorage.getItem("lang")
                        : "ar"
                }`
        );
    }

    getDoctorByID(id) {
        let url =
            `${environment.baseUrl}` +
            "/doctors/" +
            `${id}` +
            "?" +
            `lang=${
                localStorage.getItem("lang")
                    ? localStorage.getItem("lang")
                    : "ar"
            }`;
        return this.httpClient.get(url);
    }

    getDoctorReviews(id) {
        let url =
            `${environment.baseUrl}` +
            "/doctors/" +
            `${id}` +
            "/reviews" +
            "?" +
            `lang=${
                localStorage.getItem("lang")
                    ? localStorage.getItem("lang")
                    : "ar"
            }`;
        return this.httpClient.get(url);
    }

    getDoctorSessionByDate(id, date) {
        let url =
            `${environment.baseUrl}` +
            "/doctors/" +
            `${id}` +
            "/get-sessions-by-date/" +
            "?" +
            `lang=${
                localStorage.getItem("lang")
                    ? localStorage.getItem("lang")
                    : "ar"
            }` +
            "&date=" +
            `${date}`;
        return this.httpClient.get(url);
    }

    postSession(id) {
        return this.httpClient.post(
            `${environment.baseUrl}` + "/sessions/" + `${id}` + "/book",
            ""
        );
    }

    subscribe(dataModel) {
        return this.httpClient.post(
            `${environment.baseUrl}` + "/subscribe",
            dataModel
        );
    }

    getHomeInfo() {
        return this.httpClient.get(
            `${environment.baseUrl}` +
                "/home?" +
                `lang=${
                    localStorage.getItem("lang")
                        ? localStorage.getItem("lang")
                        : "ar"
                }`
        );
    }

    getCountries() {
        return this.httpClient.get(
            `${environment.baseUrl}` +
                "/countries?" +
                `lang=${
                    localStorage.getItem("lang")
                        ? localStorage.getItem("lang")
                        : "ar"
                }`
        );
    }

    sendMessage(dataModel: any) {
        let formData: FormData = new FormData();
        formData.append("name", dataModel.name);
        formData.append("title", dataModel.title);
        formData.append("email", dataModel.email);
        formData.append("phone", dataModel.phone);
        formData.append("message", dataModel.message);
        formData.append("country_id", dataModel.country_id);
        if (dataModel.files && dataModel.files.lenght) {
            formData.append("files", dataModel.files);
        }
        return this.httpClient.post(
            `${environment.baseUrl}` + "/contact-us",
            formData
        );
    }
}
