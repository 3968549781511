<app-header-two></app-header-two>

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6 text-left">
                <div class="partner-item">
                    <!-- <mat-card class="disease-card"> -->
                    <mat-card
                        class="shadow disease-card shadow p-3 mb-5 bg-white rounded"
                    >
                        <!-- <mat-card class="mat-card mat-focus-indicator shadow p-3 mb-5 bg-white rounded"> -->
                        <img
                            [src]="disease?.category?.image?.url"
                            alt="image"
                        />

                        <mat-card-content>
                            <p class="library-name">
                                <span>{{ disease.name }}</span>
                            </p>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <div class="col-lg-8 text-center" style="margin-top: 20px">
                <div class="col-flex">
                    <div style="flex: 3">
                        <h2 class="name">{{ disease.category.title }}</h2>
                    </div>
                    <div
                        style="flex: 5; margin-top: -20px; margin-left: -120px"
                    >
                        <p class="description" [innerHtml]="disease.text"></p>
                    </div>

                    <div class="row">
                        <div
                            class="col-lg-2 top-space"
                            *ngFor="let tag of disease.tags"
                        >
                            <a class="learn-more-btn">{{ tag }} </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 text-left">
                <h2 class="name">All Diseases</h2>
            </div>
            <div class="col-lg-12 text-left">
                <p
                    class="disease-details"
                    *ngFor="let textPoint of disease.text_points"
                >
                    <span class="detail-title">{{ textPoint.title }} :</span>
                    <span class="detail-value">{{ textPoint.text }}</span>
                </p>
            </div>
        </div>
    </div>
</section>
