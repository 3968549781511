import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-home-one",
    templateUrl: "./home-one.component.html",
    styleUrls: ["./home-one.component.scss"],
})
export class HomeOneComponent implements OnInit {
    lang: string = "";
    home: any = {};
    constructor(
        private translateService: TranslateService,
        private apiService: ApiService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.lang = localStorage.getItem("lang");
        this.translateService.onLangChange.subscribe((lang) => {
            // console.log(lang)
            this.lang = lang["lang"];
            console.log(lang);
        });

        this.fillHomeInfo();
    }

    ngOnInit(): void {}

    subscribe(plan) {
        this.router.navigate(["/subscribe", { plan: plan }], {
            relativeTo: this.route,
        });
    }

    fillHomeInfo() {
        this.apiService.getHomeInfo().subscribe((res) => {
            if (res["data"]) this.home = res["data"];
        });
    }

    navigateToViewNew(obj) {
        this.router.navigate(["/single-new", { id: obj.id }], {
            relativeTo: this.route,
        });
    }

   

    navigateToViewInitiative(obj){
        this.router.navigate(["/single-wellness", { id: obj.id }], {
            relativeTo: this.route,
        });
    }

 
}
