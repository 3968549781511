<app-header-two></app-header-two>

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Sorry Something Went Wrong</h2>
        </div>

        <div class="page-description">
            <span
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor Eget arcu dictum varius duis at.</span
            >
        </div>

        <div class="col-flex">
            <div class="row-flex">
                <div style="flex: 10; text-align: center">
                    <img src="assets/img/wrong.png" width="150px" />
                </div>
            </div>

            <br>
            <br>

            <div class="row-flex">
                <div style="flex: 10">
                    <div class="destinations-btn-box">
                        <button
                            mat-raised-button
                            color="primary"
                            class="default-btn"
                            routerLink="/"
                        >
                            {{ "BACK TO HOME" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
