import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogMessageComponent } from '../components/common/dialog-message/dialog-message.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(private snackBar: MatSnackBar, private dialog:MatDialog) {
  }


  openValidationDialog(
    icon: String,
    title: String,
    message: String,
    width?: string,
    cancelButton?: boolean
  ): Observable<any> {
    let defaultWidth: string = "500px";

    const dialogRef = this.dialog.open(DialogMessageComponent, {
      width: width ? width : defaultWidth,
      data: {
        icon: icon,
        title: title,
        message: message,
        cancelButton: cancelButton ? true : false,
      },
    });
    return dialogRef.afterClosed();
  }



  snackMessage(message){
      this.snackBar.open(message, 'OK', {duration:5000})
  }


 
}
