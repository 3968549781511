<app-header-two></app-header-two>

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                {{
                    "Contact Us"
                        | translate
                }}
            </h2>
        </div>
       
        <mat-card class="ask-card">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="billing-details">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label
                                            >{{ "Full Name" | translate }}
                                            <span class="required"
                                                >*</span
                                            ></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="txtName"
                                            [(ngModel)]="user.name"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label
                                            >{{ "Email" | translate }}
                                            <span class="required"
                                                >*</span
                                            ></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="txtEmail"
                                            [(ngModel)]="user.email"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label
                                            >{{ "Mobile" | translate }}
                                            <span class="required"
                                                >*</span
                                            ></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="txtMobile"
                                            [(ngModel)]="user.phone"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label
                                            >{{ "Country" | translate }}
                                            <span class="required"
                                                >*</span
                                            ></label
                                        >
                                        <mat-form-field appearance="fill">
                                            <mat-select
                                                name="txtCountry"
                                                (selectionChange)="
                                                    setFieldID($event)
                                                "
                                            >
                                            <mat-select-filter [array]="countries" [displayMember]="'name'" (filteredReturn)="filteredCountries = $event"></mat-select-filter>

                                                <mat-option
                                                    *ngFor="
                                                        let country of filteredCountries
                                                    "
                                                    [value]="country.id"
                                                    >{{
                                                        country.name
                                                    }}</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label
                                            >{{ "Title" | translate }}
                                            <span class="required"
                                                >*</span
                                            ></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="txtTitle"
                                            [(ngModel)]="user.title"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label
                                            >{{ "Message" | translate }}
                                            <span class="required"
                                                >*</span
                                            ></label
                                        >
                                        <textarea
                                            type="text"
                                            class="form-control"
                                            name="txtMsg"
                                            [(ngModel)]="user.message"
                                        ></textarea>
                                    </div>
                                </div>
                                <input
                                    #fileInput
                                    type="file"
                                    (change)="onChange($event)"
                                    style="display: none"
                                />
                                <div
                                    class="col-lg-12"
                                    style="text-align: center"
                                >
                                    <p *ngIf="fileName">{{ fileName }}</p>
                                </div>

                                <div class="col-lg-12 text-center top-space">
                                    <div class="destinations-btn-box">
                                        <button
                                            mat-raised-button
                                            color="primary"
                                            class="default-btn upload-btn"
                                            (click)="fileInput.click()"
                                        >
                                            {{
                                                "Upload File Or Photo"
                                                    | translate
                                            }}
                                        </button>
                                    </div>
                                </div>

                                <div class="col-lg-12 text-center top-space">
                                    <div class="destinations-btn-box">
                                        <button
                                            mat-raised-button
                                            color="primary"
                                            class="default-btn"
                                            (click)="postQuestion()"
                                        >
                                            {{ "Send" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</section>
