<app-header-two></app-header-two>

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row top-space-card">
            <div class="col-lg-8">
                <div class="col-flex">
                    <mat-card class="shadow session-card">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="row-flex">
                                    <div class="pic-container">
                                        <img
                                            class="doctor-pic"
                                            [src]="
                                                doctor?.pic?.url
                                                    ? doctor.pic.ur
                                                    : 'https://material.angular.io/assets/img/examples/shiba1.jpg'
                                            "
                                        />
                                    </div>
                                    <div class="col-fle nx doc-info">
                                        <p class="doc-name">
                                            {{ doctor.name }}
                                        </p>
                                        <p class="doc-specialty">
                                            {{ doctor.position }}
                                        </p>
                                        <div class="star-rating">
                                            <i class="bx bxs-star"></i>
                                            <i class="bx bxs-star"></i>
                                            <i class="bx bxs-star"></i>
                                            <i class="bx bxs-star"></i>
                                            <i class="bx bxs-star"></i>
                                        </div>
                                        <div
                                            class="row-flex"
                                            style="
                                                white-space: nowrap;
                                                color: #707070 !important;
                                                font-size: 12px !important;
                                                margin-top: 10px !important;
                                            "
                                        >
                                            <div style="flex: 1">
                                                <mat-icon
                                                    style="font-size: 20px"
                                                    >money</mat-icon
                                                >
                                            </div>
                                            <div
                                                style="
                                                    flex: 1;
                                                    margin-top: -10px;
                                                "
                                            >
                                                {{ doctor.pricing }}$
                                            </div>
                                            <div style="flex: 0.5"></div>
                                            <div>
                                                <mat-icon
                                                    style="font-size: 20px"
                                                    >refresh</mat-icon
                                                >
                                            </div>
                                            <div style="margin-top: -10px">
                                                <span>
                                                    {{
                                                        doctor.session_duration
                                                    }}
                                                    Minutes</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div
                                    class="col-flex doc-data"
                                    style="white-space: nowrap"
                                >
                                    <p>
                                        Country Of Residence:
                                        {{ doctor.country_name }}
                                    </p>
                                    <p>Join Date: 7</p>
                                    <p>
                                        Months Ago Main Focus:
                                        {{ doctor.position }}
                                    </p>
                                    <p>
                                        Specialties: {{ doctor.specialities }}
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-3"></div>
                            <div class="col-lg-8 specialized-container">
                                <h3>SPECIALIZED TREATMENTS</h3>
                                <p>
                                    {{ doctor.specialized_treatment }}
                                </p>
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                    </mat-card>
                    <br />
                    <br />
                    <mat-card class="shadow about-card">
                        <mat-tab-group>
                            <mat-tab label="Doctor Information">
                                <div class="row">
                                    <div class="about-doctor">
                                        <h4>About</h4>
                                        <p>
                                            {{ doctor.info }}
                                        </p>

                                        <h4>Contact Information</h4>
                                        <p>
                                            {{ doctor.contact }}
                                        </p>
                                    </div>
                                </div>
                            </mat-tab>

                            <mat-tab label="Patients' Reviews">
                                <div class="row">
                                    <div class="doctor-review">
                                        <div class="star-rating">
                                            <i class="bx bxs-star"></i>
                                            <span
                                                >{{ doctor.rating }} ({{
                                                    doctor.reviews_count
                                                }}
                                                Reviews)</span
                                            >
                                        </div>
                                    </div>
                                    <div class="col-flex">
                                        <div class="row-flex top-space">
                                            <div style="flex: 5">
                                                <span class="rate-name"
                                                    >Communication</span
                                                >
                                            </div>
                                            <div style="flex: 1"></div>
                                            <div style="flex: 3">
                                                <mat-progress-bar
                                                    class="audio-progress-bar"
                                                    mode="determinate"
                                                    value="100"
                                                ></mat-progress-bar>
                                            </div>
                                            <div style="flex: 5"></div>
                                        </div>
                                        <div class="row-flex top-space">
                                            <div style="flex: 5">
                                                <span class="rate-name"
                                                    >Understanding Of The
                                                    Situation</span
                                                >
                                            </div>
                                            <div style="flex: 1"></div>
                                            <div style="flex: 3">
                                                <mat-progress-bar
                                                    mode="determinate"
                                                    value="100"
                                                ></mat-progress-bar>
                                            </div>
                                            <div style="flex: 5"></div>
                                        </div>
                                        <div class="row-flex top-space">
                                            <div style="flex: 5">
                                                <span class="rate-name"
                                                    >Providing Effective
                                                    Solutions</span
                                                >
                                            </div>
                                            <div style="flex: 1"></div>
                                            <div style="flex: 3">
                                                <mat-progress-bar
                                                    mode="determinate"
                                                    value="100"
                                                ></mat-progress-bar>
                                            </div>
                                            <div style="flex: 5"></div>
                                        </div>
                                        <div class="row-flex top-space">
                                            <div style="flex: 5">
                                                <span class="rate-name"
                                                    >Commitment To Start And End
                                                    Times</span
                                                >
                                            </div>
                                            <div style="flex: 1"></div>
                                            <div style="flex: 3">
                                                <mat-progress-bar
                                                    mode="determinate"
                                                    value="100"
                                                ></mat-progress-bar>
                                            </div>
                                            <div style="flex: 5"></div>
                                        </div>
                                        <div
                                            class="row-flex top-space top-line"
                                        ></div>
                                        <div class="top-space"></div>
                                        <div
                                            class="col-flex reviews"
                                            *ngFor="let review of reviews"
                                        >
                                            <p class="patient-name">
                                                {{ review.user_name }}
                                            </p>
                                            <div class="star-rating">
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                            </div>
                                            <p class="patient-feedback">
                                                {{ review.text }}
                                            </p>
                                        </div>
                                        <div class="top-space"></div>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </mat-card>
                </div>
            </div>
            <div class="col-lg-4">
                <mat-card class="shadow">
                    <mat-calendar [(selected)]="selected"></mat-calendar>
                </mat-card>
                <mat-card class="time-card shadow">
                    <div class="row">
                        <div
                            class="col-lg-3 col-md-3 col-sm-3 col-xs-3"
                            style="width: 25% !important"
                            *ngFor="let time of times"
                            (click)="setSelectedTime(time)"
                        >
                            <span
                                class="time-value"
                                [ngClass]="{ active: time.status !== 0 }"
                                >{{ time.time }} pm</span
                            >
                        </div>
                    </div>
                </mat-card>

                <div class="destinations-btn-box">
                    <button
                        mat-raised-button
                        color="primary"
                        class="default-btn"
                        (click)="bookSession()"
                    >
                        Book Now
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
