import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { ModalService } from "../../../services/modal.service";
import { User } from "src/app/models/user.model";
import {
    FormBuilder,
    FormGroup,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../../common/shared/components/confirm-dialog/confirm-dialog.component";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    loading: boolean = false;
    user: User = new User();

    emailForm!: FormGroup;
    phoneForm!: FormGroup;
    isSubmitted = false;

    constructor(
        private authService: AuthService,
        private modal: ModalService,
        private router: Router,
        private fb: FormBuilder,
        private dialog: MatDialog
    ) {}
    ngOnInit(): void {
        this.emailForm = this.fb.group({
            email: ["", [Validators.required]],
            password: ["", Validators.required],
            checked: ["", Validators.required],
        });
        this.phoneForm = this.fb.group({
            phone: ["", [Validators.required]],
            password: ["", Validators.required],
            checked: ["", Validators.required],
        });
    }

    getphoneControlErrors(controlName: string): ValidationErrors | null {
        return this.phoneForm.controls[controlName].errors;
    }
    getEmailControlErrors(controlName: string): ValidationErrors | null {
        return this.emailForm.controls[controlName].errors;
    }

    forgetPW(type: number, value: string) {
        if (value === "") {
            this.modal.snackMessage(
                "Enter your " + (type == 1 ? "email" : "phone")
            );
            return;
        }
        let body = {};
        if (type === 0) {
            body = {
                is_email: type,
                phone: value,
            };
        } else {
            body = {
                is_email: type,
                email: value,
            };
        }

        this.authService.forgetPw(body).subscribe(
            (res) => {
                this.dialog.open(ConfirmDialogComponent, {
                    panelClass: "confirm-dialog-panel",
                    width: "30vw",
                    disableClose: true,
                    minWidth: "500px",
                    data: {
                        msg: "Password Reset Link Sent! Please check your verified email or phone number for the reset link. If you don't see it, be sure to check your spam or junk folder",
                        showLogin: true,
                    },
                });
            },
            (error) => {
                this.loading = false;
                if (error.status === 404) {
                    if (type == 1) {
                        this.modal.snackMessage(
                            " Email is not registered or your account hasn't been activated yet."
                        );
                    } else {
                        this.modal.snackMessage(
                            " Phone is not registered or your account hasn't been activated yet."
                        );
                    }
                } else {
                    this.modal.snackMessage(error["error"]["error"]);
                }
            }
        );
    }

    login(type: string) {
        if (this.loading) return;

        this.loading = true;
        this.isSubmitted = true;

        if (type == "email") {
            this.authService
                .login(
                    this.emailForm.value.email,
                    this.emailForm.value.password,
                    1
                )
                .subscribe(
                    (res) => {
                        this.loading = false;

                        if (res["success"]) {
                            this.modal.snackMessage("Welcome To ITAMMEN");
                            this.router.navigate(["/"]);
                        } else {
                            this.modal.snackMessage(res["message"]);
                        }
                    },
                    (error) => {
                        this.loading = false;
                        if (error.status === 404)
                            this.modal.snackMessage(
                                " Email is not registered or your account hasn't been activated yet."
                            );
                        else {
                            this.modal.snackMessage(error["error"]["error"]);
                        }
                    }
                );
        }
        if (type == "phone") {
            this.authService
                .phonelogin(
                    this.phoneForm.value.phone,
                    this.phoneForm.value.password,
                    0
                )
                .subscribe(
                    (res) => {
                        this.loading = false;

                        if (res["success"]) {
                            this.modal.snackMessage("Welcome To ITAMMEN");
                            this.router.navigate(["/"]);
                        } else {
                            this.modal.snackMessage(res["message"]);
                        }
                    },
                    (error) => {
                        this.loading = false;
                        this.loading = false;
                        if (error.status === 404)
                            this.modal.snackMessage(
                                " Phone is not registered or your account hasn't been activated yet."
                            );
                        else {
                            this.modal.snackMessage(error["error"]["error"]);
                        }
                    }
                );
        }
    }
}
