<app-header-two></app-header-two>

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Subscribe</h2>
        </div>
        <mat-card class="subscribe-card">
            <div class="row">
                <div class="col-lg-12">
                    <mat-stepper linear #stepper>
                        <mat-step #firstStepper>
                            <ng-template matStepLabel>{{
                                firstTabName
                            }}</ng-template>
                        </mat-step>
                        <mat-step editable="true" #secondStepper>
                            <ng-template matStepLabel
                                >Personal Data</ng-template
                            >
                            <div class="row">
                                <div class="billing-details">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Name </label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    name="txtName"
                                                    [(ngModel)]="
                                                        subscription.name
                                                    "
                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Email </label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    name="txtEmail"
                                                    [(ngModel)]="
                                                        subscription.email
                                                    "
                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Password </label>
                                                <input
                                                    type="password"
                                                    class="form-control"
                                                    name="txtPassword"
                                                    [(ngModel)]="
                                                        subscription.password
                                                    "
                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group mb-3">
                                                <label
                                                    >{{
                                                        "Date Of Birth"
                                                            | translate
                                                    }}
                                                </label>
                                                <mat-form-field>
                                                    <input
                                                        matInput
                                                        [matDatepicker]="
                                                            pickerEnd
                                                        "
                                                        name="txtDateOfBirth"
                                                        class="form-control birthdate"
                                                        name="txtDateOfBirth"
                                                        [(ngModel)]="dob"
                                                    />
                                                    <input type="hidden" />
                                                    <mat-datepicker-toggle
                                                        matSuffix
                                                        [for]="pickerEnd"
                                                    ></mat-datepicker-toggle>
                                                    <mat-datepicker
                                                        #pickerEnd
                                                    ></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label
                                                    >{{ "Gender" | translate }}
                                                </label>
                                                <mat-form-field
                                                    appearance="fill"
                                                >
                                                    <mat-select
                                                        name="txtGender"
                                                        [(ngModel)]="
                                                            subscription.gender
                                                        "
                                                    >
                                                        <mat-option value="male"
                                                            >Male</mat-option
                                                        >
                                                        <mat-option
                                                            value="female"
                                                            >Female</mat-option
                                                        >
                                                        <mat-option
                                                            value="other"
                                                            >Other</mat-option
                                                        >
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Phone </label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    name="txtPhone"
                                                    [(ngModel)]="
                                                        subscription.phone
                                                    "
                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label
                                                    >Height
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    name="txtHeight"
                                                    [(ngModel)]="
                                                        subscription.height
                                                    "
                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label
                                                    >Weight
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    name="txtWeight"
                                                    [(ngModel)]="
                                                        subscription.weight
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label
                                                    >{{
                                                        "Blood Type" | translate
                                                    }}
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <mat-form-field
                                                    appearance="fill"
                                                >
                                                    <mat-select
                                                        name="txtBloodType"
                                                        [(ngModel)]="
                                                            subscription.blood_type
                                                        "
                                                    >
                                                        <mat-option value="A+"
                                                            >A+</mat-option
                                                        >
                                                        <mat-option value="B+"
                                                            >B+</mat-option
                                                        >
                                                        <mat-option value="AB+"
                                                            >AB+</mat-option
                                                        >

                                                        <mat-option value="O+"
                                                            >O+</mat-option
                                                        >
                                                        <mat-option value="B-"
                                                            >B-</mat-option
                                                        >
                                                        <mat-option value="AB-"
                                                            >AB-</mat-option
                                                        >

                                                        <mat-option value="O-"
                                                            >O-</mat-option
                                                        >
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <label
                                                >Marital Status
                                                <span class="required"
                                                    >*</span
                                                ></label
                                            >
                                            <br />
                                            <mat-radio-group
                                                color="primary"
                                                name="txtMartialStatus"
                                                [(ngModel)]="
                                                    subscription.marital_status
                                                "
                                            >
                                                <mat-radio-button value="single"
                                                    >Single</mat-radio-button
                                                >
                                                <mat-radio-button
                                                    value="married"
                                                    >Married</mat-radio-button
                                                >
                                                <mat-radio-button
                                                    value="separated"
                                                    >Complicated</mat-radio-button
                                                >
                                            </mat-radio-group>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <label
                                                >Have You Had Any Surgeries
                                                Before?</label
                                            >
                                            <br />
                                            <mat-radio-group color="primary">
                                                <mat-radio-button value="1"
                                                    >Yes</mat-radio-button
                                                >
                                                <mat-radio-button value="2"
                                                    >No</mat-radio-button
                                                >
                                            </mat-radio-group>
                                        </div>

                                        <div
                                            class="col-lg-12 text-center top-space"
                                        >
                                            <div class="destinations-btn-box">
                                                <button
                                                    mat-raised-button
                                                    color="primary"
                                                    class="default-btn"
                                                    (click)="gotToCheckout()"
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-step>
                        <mat-step editable="true" #thirdStepper>
                            <ng-template matStepLabel>Check Out</ng-template>
                            <div class="row">
                                <div class="billing-details">
                                    <div class="row">
                                        <div
                                            class="col-lg-12 col-mg-12 text-left"
                                        >
                                            <h3>Name Of Card Holder</h3>
                                        </div>

                                        <div class="col-lg-2 half-width">
                                            <mat-card class="card-holder">
                                                <img
                                                    src="assets/img/kindpng_2918629.png"
                                                />
                                            </mat-card>
                                        </div>
                                        <div class="col-lg-2 half-width">
                                            <mat-card class="card-holder">
                                                <img
                                                    src="assets/img/kindpng_2413689.png"
                                                />
                                            </mat-card>
                                        </div>

                                        <div class="col-lg-4"></div>
                                        <div
                                            class="col-lg-4"
                                            *ngIf="firstTabName == 'Sign Up'"
                                        >
                                            <mat-radio-group
                                                aria-labelledby="example-radio-group-label"
                                                class="example-radio-group"
                                                color="primary"
                                                name="txtPlan"
                                                [(ngModel)]="subscription.plan"
                                            >
                                                <div
                                                    class="row-flex plan-container"
                                                >
                                                    <div style="flex: 3">
                                                        <mat-radio-button
                                                            class="example-radio-button"
                                                            value="plan_a"
                                                        >
                                                            Basic
                                                        </mat-radio-button>
                                                    </div>
                                                    <div style="flex: 1"></div>

                                                    <div
                                                        style="
                                                            flex: 1;
                                                            text-align: end;
                                                        "
                                                    >
                                                        <span
                                                            class="subscription-cost"
                                                            >5$</span
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    class="row-flex plan-container"
                                                >
                                                    <div style="flex: 3">
                                                        <mat-radio-button
                                                            class="example-radio-button"
                                                            value="plan_b"
                                                        >
                                                            Professional
                                                        </mat-radio-button>
                                                    </div>
                                                    <div style="flex: 1"></div>

                                                    <div
                                                        style="
                                                            flex: 1;
                                                            text-align: end;
                                                        "
                                                    >
                                                        <span
                                                            class="subscription-cost"
                                                            >10$</span
                                                        >
                                                    </div>
                                                </div>
                                            </mat-radio-group>
                                        </div>

                                        <div
                                            class="col-lg-4"
                                            style="margin-bottom: 20px"
                                            *ngIf="firstTabName !== 'Sign Up'"
                                        >
                                            <div
                                                class="col-flex booking-details-container"
                                            >
                                                <div class="booking-details">
                                                    <span>Booking Details</span>
                                                </div>
                                                <div
                                                    class="col-flex details-container"
                                                >
                                                    <div class="row-flex">
                                                        <div
                                                            class="icon-container"
                                                        >
                                                            <mat-icon
                                                                >money</mat-icon
                                                            >
                                                        </div>
                                                        <div
                                                            style="flex: 1"
                                                            class="cost-container"
                                                        >
                                                            <span
                                                                >{{
                                                                    session
                                                                        .doctor
                                                                        .pricing
                                                                }}$</span
                                                            >
                                                        </div>
                                                        <div
                                                            style="flex: 10"
                                                        ></div>
                                                    </div>

                                                    <div class="row-flex">
                                                        <div
                                                            class="icon-container"
                                                        >
                                                            <mat-icon
                                                                >videocam</mat-icon
                                                            >
                                                        </div>
                                                        <div
                                                            style="flex: 1"
                                                            class="cost-container"
                                                        >
                                                            <span
                                                                >Video
                                                                Call</span
                                                            >
                                                        </div>
                                                        <div
                                                            style="flex: 10"
                                                        ></div>
                                                    </div>

                                                    <div class="row-flex">
                                                        <div
                                                            class="icon-container"
                                                        >
                                                            <mat-icon
                                                                >schedule</mat-icon
                                                            >
                                                        </div>
                                                        <div
                                                            style="flex: 1"
                                                            class="cost-container"
                                                        >
                                                            <span
                                                                >{{
                                                                    session.duration
                                                                }}-Min Session
                                                                With</span
                                                            >
                                                        </div>
                                                        <div
                                                            style="flex: 10"
                                                        ></div>
                                                    </div>

                                                    <div class="row-flex">
                                                        <div
                                                            class="icon-container"
                                                        >
                                                            <mat-icon
                                                                >person</mat-icon
                                                            >
                                                        </div>
                                                        <div
                                                            style="flex: 1"
                                                            class="cost-container"
                                                        >
                                                            <span
                                                                >{{
                                                                    session
                                                                        .doctor
                                                                        .name
                                                                }},</span
                                                            >
                                                        </div>
                                                        <div
                                                            style="flex: 10"
                                                        ></div>
                                                    </div>

                                                    <div class="row-flex">
                                                        <div
                                                            class="icon-container"
                                                        >
                                                            <mat-icon
                                                                >calendar_month</mat-icon
                                                            >
                                                        </div>
                                                        <div
                                                            style="flex: 1"
                                                            class="cost-container"
                                                        >
                                                            <span
                                                                >{{
                                                                    session.date
                                                                }}
                                                                {{
                                                                    session.time
                                                                }}</span
                                                            >
                                                        </div>
                                                        <div
                                                            style="flex: 10"
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="col-flex">
                                                <div
                                                    class="row-flex"
                                                    style="
                                                        white-space: nowrap !important;
                                                    "
                                                >
                                                    <div style="flex: 1">
                                                        <mat-icon
                                                            >credit_card</mat-icon
                                                        >
                                                    </div>
                                                    <div
                                                        style="
                                                            flex: 3;
                                                            margin-top: -5px;
                                                        "
                                                    >
                                                        <span
                                                            style="
                                                                font-weight: bold;
                                                            "
                                                            >Saved Card #
                                                            ***********1234</span
                                                        >
                                                    </div>
                                                    <div style="flex: 1">
                                                        <mat-icon
                                                            >done</mat-icon
                                                        >
                                                    </div>
                                                    <div style="flex: 10"></div>
                                                </div>

                                                <div
                                                    *ngIf="!addNewCredit"
                                                    class="row-flex"
                                                    style="
                                                        margin-top: 20px;
                                                        white-space: nowrap !important;
                                                    "
                                                >
                                                    <div style="flex: 1"></div>
                                                    <div
                                                        style="
                                                            flex: 3;
                                                            margin-top: -5px;
                                                        "
                                                    >
                                                        <span
                                                            class="new-credit"
                                                            (click)="
                                                                addNewCredit =
                                                                    !addNewCredit
                                                            "
                                                            >Add New
                                                            Credit</span
                                                        >
                                                    </div>
                                                    <div style="flex: 1"></div>
                                                    <div style="flex: 10"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="col-lg-12 col-md-3"
                                            *ngIf="addNewCredit"
                                        >
                                            <div class="form-group">
                                                <label
                                                    >Name Of Card Holder
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Text"
                                                />
                                            </div>
                                        </div>

                                        <div
                                            class="col-lg-6 col-md-6"
                                            *ngIf="addNewCredit"
                                        >
                                            <div class="form-group">
                                                <label
                                                    >Credit Card Number
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Text"
                                                />
                                            </div>
                                        </div>

                                        <div
                                            class="col-lg-3 col-md-3"
                                            *ngIf="addNewCredit"
                                        >
                                            <div class="form-group">
                                                <label
                                                    >Expiration
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="YY/MM"
                                                />
                                            </div>
                                        </div>

                                        <div
                                            class="col-lg-3 col-md-3"
                                            *ngIf="addNewCredit"
                                        >
                                            <div class="form-group">
                                                <label
                                                    >CVV
                                                    <span class="required"
                                                        >*</span
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="CVV"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="col-lg-12 text-center top-space"
                                        >
                                            <div class="destinations-btn-box">
                                                <button
                                                    mat-raised-button
                                                    color="primary"
                                                    class="default-btn"
                                                    (click)="checkout()"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-step>
                    </mat-stepper>
                </div>
            </div>
        </mat-card>
    </div>
</section>
