<div mat-dialog-content>
    <div class="form-group">
        <label
            >{{ "Add New Phone Number" | translate }}
            <span class="required">*</span></label
        >
        <input
            type="text"
            class="form-control"
            placeholder="New Phone Number"
            name="txtPhone"
            [(ngModel)]="phone"
        />
    </div>
    <div class="destinations-btn-box">
        <button
            mat-raised-button
            color="primary"
            class="default-btn"
            (click)="confirm()"
        >
            Submit
        </button>
    </div>
</div>
