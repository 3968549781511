import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { Subscription, timer } from "rxjs";
import { CodeInputModule } from "angular-code-input";
import { CommonModule } from "@angular/common";
import { take } from "rxjs/operators";
@Component({
    selector: "app-otp-dialog",
    templateUrl: "./otp-dialog.component.html",
    styleUrls: ["./otp-dialog.component.scss"],
    standalone: true,
    imports: [CommonModule, CodeInputModule],
})
export class OTPDialogComponent {
    countDown!: Subscription;
    counter = 60;
    tick = 1000;
    disabled = true;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        @Inject(MAT_DIALOG_DATA)
        public data: { entity: any; action: string; note: string }
    ) {
        console.log(this.data.entity);
    }
    route() {
        this.dialog.closeAll();
        this.router.navigate(["login"]);
    }

    ngOnInit(): void {
        this.countDownSubscription();
    }

    countDownSubscription() {
        this.countDown = timer(0, this.tick)
            .pipe(take(this.counter))
            .subscribe(() => {
                --this.counter;
                if (this.counter == 0) {
                    this.countDown.unsubscribe();
                    this.disabled = false;
                }
            });
    }

    transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return (
            ("00" + minutes).slice(-2) +
            ":" +
            ("00" + Math.floor(value - minutes * 60)).slice(-2)
        );
    }

    onCodeChanged(code: string) {}

    onCodeCompleted(code: string) {
        this.router.navigate(["/login"]);
    }

    resend() {
        if (!this.disabled) {
            this.disabled = true;
            this.counter = 60;
            this.countDownSubscription();
        }
    }
}
