import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { observable, BehaviorSubject, Subject } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class SwitchLanguageService {
    constructor(private translate: TranslateService) {}

    ChangeLanguage(language: string) {
        if (language == "ar") {
            console.log(language);
            document.documentElement.dir = "rtl";
            this.translate.use("ar");
            localStorage.setItem("lang", "ar");
        } else {
            //this.config.layout.dir = 'ltr'
            document.documentElement.dir = "ltr";
            localStorage.setItem("lang", "en");
            this.translate.use("en");
        }
        this.translate.use(language);
    }

    getCurrentLang() {
        return localStorage.getItem("lang");
    }
}
