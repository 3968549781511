import { Component, OnInit } from "@angular/core";
import {
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd,
} from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { filter } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { SwitchLanguageService } from "./services/switch-language.service";
declare let $: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(
        private router: Router,
        private switchLang: SwitchLanguageService
    ) {
        
    }

    ngOnInit() {
        this.recallJsFuntions();
        this.checkCurrentLang();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                $.getScript("../assets/js/main.js");
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    checkCurrentLang() {
        // this.translate.use(
        //     localStorage.getItem("lang") ? localStorage.getItem("lang") : "ar"
        // );
        // if (this.translate.currentLang == "ar")
        //     document.documentElement.dir = "rtl";
        // else document.documentElement.dir = "ltr"

        this.switchLang.ChangeLanguage(
            localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
        );
    }
}
