<app-header-two></app-header-two>

<div
    class="main-banner banner-bg1 jarallax"
    data-jarallax='{"speed": 0.3}'
    [lang]="lang"
>
    <div class="row">
        <div class="main-banner-content">
            <h1 class="consult-doc">
                {{ "Consult A Doctor Anytime" | translate }}
                <span>{{ "Anywhere" | translate }}</span>
            </h1>
            <div class="destinations-btn-box">
                <div class="row" style="margin-top: 40px">
                    <div class="col-lg-2">


                        <button
                            routerLink="/ask-doctor"
                            mat-raised-button
                            class="default-btn m-3"
                            style="padding: 8px 30px 8px !important"
                        >
                            <img
                                src="../../../../assets/images/message-svgrepo-com (2).svg"
                                style="width: 25px; padding-right: 5px"
                            />
                            {{ "ASK ADOCTOR" | translate }}
                        </button>
                    </div>

                    <div class="col-lg-2 btn1-container">
                        <!-- <a class="btn1"
                            ><img
                                src="../../../../assets/images/video-svgrepo-com (2).svg"
                                style="width: 25px; padding-right: 5px"
                            />
                            {{ "CALL ADOCTOR" | translate }}</a
                        > -->

                        <button mat-raised-button class="btn1">
                            <img
                                src="../../../../assets/images/video-svgrepo-com (2).svg"
                                style="width: 25px; padding-right: 5px"
                            />
                            {{ "CALL ADOCTOR" | translate }}
                        </button>
                    </div>
                    <div class="col-lg-8"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="listing-categories-area pt-100 pb-70" [lang]="lang">
    <div class="container">
        <div class="section-title">
            <h2>{{ "Medications" | translate }}</h2>
        </div>

        <div class="row">
            <div
                class="col-lg-4 col-md-4 col-sm-6"
                *ngFor="let medication of home.medications"
            >
                <div
                    class="single-categories-listing-item"
                    [style.background-image]="
                        'url(' + medication?.image?.url + ')'
                    "
                >
                    <div class="icon"></div>
                    <h3>{{ medication.title }}</h3>
                    <span [innerHtml]="medication.text"></span>

                    <a
                        (click)="navigateToViewNew(medication)"
                        class="learn-more-btn"
                        > {{ "Learn More" | translate }}<i class="bx bx-chevron-right"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-feedback [prescriptions]="home.prescriptions"></app-feedback>

<app-partner [categories]="home.health_library"></app-partner>

<!-- <section class="listing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Discover</span>
            <h2>Wellness <span class="initiatives">Medications</span></h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let initiative of home.wellness_initiatives">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a>
                            <img
                                [src]="initiative.image?.url"
                                alt="image"
                            />
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category"
                            >{{initiative.title}}</span
                        >
                        <h3>
                            <a [innerHtml]="initiative.text"
                                ></a
                            >
                        </h3>
                        <a (click)="navigateToViewInitiative(initiative)" class="details-btn"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>


        </div>
    </div>
</section> -->

<app-process></app-process>

<section class="blog-area ptb-100" dir="ltr">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="subscribe basic">
                        <h1>Basic</h1>
                        <h1>$5</h1>
                        <br />
                        <p>
                            <mat-icon>done</mat-icon>
                            <span>2 Text Questions Per Month</span>
                        </p>
                        <p>
                            <mat-icon>done</mat-icon>
                            <span>Access To Premium Content</span>
                        </p>

                        <div class="destinations-btn-box basic-btn">
                            <!-- <a (click)="subscribe('a')" class="default-btn"
                                >Subscribe Now</a
                            > -->

                            <button
                                mat-raised-button
                                class="default-btn"
                                (click)="subscribe('a')"
                            >
                                Subscribe Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="subscribe professional">
                        <h1>Professional</h1>
                        <h1>$10</h1>
                        <br />
                        <p>
                            <mat-icon>done</mat-icon>
                            <span>2 Text Questions Per Month</span>
                        </p>
                        <p>
                            <mat-icon>done</mat-icon>
                            <span>Access To Premium Content</span>
                        </p>

                        <div class="destinations-btn-box basic-btn">
                            <!-- <a (click)="subscribe('b')" class="default-btn"
                                >Subscribe Now</a
                            > -->

                            <button
                                mat-raised-button
                                class="default-btn"
                                (click)="subscribe('b')"
                            >
                                Subscribe Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
