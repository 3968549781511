<div mat-dialog-content>
    <div class="form-group">
        <label
            >{{
                "Add New Password" | translate
            }}
            <span class="required"
                >*</span
            ></label
        >
        <input
            type="password"
            class="form-control"
            placeholder="New Password"
            name="txtPassword"
            [(ngModel)]="password"
        />
    </div>
    <div class="destinations-btn-box">
        <button
            mat-raised-button
            color="primary"
            class="default-btn"
            (click)="confirm()"
        >
            Submit
        </button>
    </div>
</div>

