import { DatePipe } from "@angular/common";
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../../services/api.service";
import { ModalService } from "../../../services/modal.service";
import { DatePickerComponent } from "../../common/date-picker/date-picker.component";
import { EmailDialogComponent } from "../../common/email-dialog/email-dialog.component";
import { PasswordDialogComponent } from "../../common/password-dialog/password-dialog.component";
import { PhoneNumberDialogComponent } from "../../common/phone-number-dialog/phone-number-dialog.component";
import { PhoneNumberVerificationDialogComponent } from "../../common/phone-number-verification-dialog/phone-number-verification-dialog.component";

@Component({
    selector: "app-patient-profile",
    templateUrl: "./patient-profile.component.html",
    styleUrls: ["./patient-profile.component.scss"],
})
export class PatientProfileComponent implements OnInit, AfterViewInit {
    isMedicalFile: boolean = false;
    isMedicalConsultation: boolean = false;
    isSettings: boolean = true;
    isPaymentHistory: boolean = false;
    lang: string = "";
    isSessions: boolean = false;
    isWallet: boolean = false;

    medicalFile: any = {};
    setting: any = {};
    loading: boolean = false;

    questions: any[] = [];

    private _allowNotification: boolean = false;
    get allowNotification(): boolean {
        return this._allowNotification;
    }
    set allowNotification(value: boolean) {
        console.log(value);
    }

    private _autoRenewal: boolean = false;
    get autoRenewal(): boolean {
        return this._autoRenewal;
    }
    set autoRenewal(value: boolean) {
        console.log(value);
    }

    private _selectedLang: string = localStorage.getItem("lang");
    get selectedLang(): string {
        return this._selectedLang;
    }
    set selectedLang(value: string) {
        console.log(value);
    }

    upcomingSessions: any[] = [];
    pastSessions: any[] = [];
    cancelledSessions: any[] = [];

    constructor(
        private translateService: TranslateService,
        private dialog: MatDialog,
        private datePipe: DatePipe,
        private modal: ModalService,
        private apiService: ApiService
    ) {
        this.lang = localStorage.getItem("lang");
        this.translateService.onLangChange.subscribe((lang) => {
            this.lang = lang["lang"];
        });

        this.fillPatientConsultations();
    }

    ngOnInit(): void {
        this.resetMedicalFile();
        this.fillPatientSessions();
    }

    ngAfterViewInit(): void {}

    changeView(value) {
        if (value == "medicalFile") {
            this.isMedicalFile = true;
            this.isMedicalConsultation = false;
            this.isSettings = false;
            this.isPaymentHistory = false;
            this.isSessions = false;
            this.isWallet = false;
        } else if (value == "medicalConsultation") {
            this.isMedicalConsultation = true;
            this.isMedicalFile = false;
            this.isSettings = false;
            this.isPaymentHistory = false;
            this.isSessions = false;
            this.isWallet = false;
        } else if (value == "settings") {
            this.isSettings = true;
            this.isMedicalConsultation = false;
            this.isMedicalFile = false;
            this.isPaymentHistory = false;
            this.isSessions = false;
            this.isWallet = false;
        } else if (value == "wallet") {
            this.isSettings = false;
            this.isMedicalConsultation = false;
            this.isMedicalFile = false;
            this.isPaymentHistory = false;
            this.isSessions = false;
            this.isWallet = true;
        } else if (value == "paymentHistory") {
            this.isSettings = false;
            this.isMedicalConsultation = false;
            this.isMedicalFile = false;
            this.isPaymentHistory = true;
            this.isSessions = false;
            this.isWallet = false;
        } else if (value == "sessions") {
            this.isSettings = false;
            this.isMedicalConsultation = false;
            this.isMedicalFile = false;
            this.isPaymentHistory = false;
            this.isSessions = true;
            this.isWallet = false;
        }
    }

    rescheduleSession() {
        //this.test()
        let defaultWidth: string = "350px";
        const dialogRef = this.dialog.open(DatePickerComponent, {
            width: defaultWidth,
            data: {
                //cancelButton: true,
            },
        });

        return dialogRef.afterClosed();
    }

    changeEmail() {
        //this.test()
        let defaultWidth: string = "350px";
        const dialogRef = this.dialog.open(EmailDialogComponent, {
            width: defaultWidth,
            data: {
                //cancelButton: true,
            },
        });

        return dialogRef.afterClosed().subscribe((res) => {
            console.log(res);
            if (res["email"]) {
                this.updatePatientProp("email", res["email"]);
            }
        });
    }

    changePassword() {
        //this.test()
        let defaultWidth: string = "350px";
        const dialogRef = this.dialog.open(PasswordDialogComponent, {
            width: defaultWidth,
            data: {
                //cancelButton: true,
            },
        });

        return dialogRef.afterClosed().subscribe((res) => {
            console.log(res);
            if (res["password"]) {
                this.updatePatientProp("password", res["password"]);
            }
        });
    }

    changePhoneNumber() {
        //this.test()
        let defaultWidth: string = "350px";
        const dialogRef = this.dialog.open(PhoneNumberDialogComponent, {
            width: defaultWidth,
            data: {
                //cancelButton: true,
            },
        });

        return dialogRef.afterClosed().subscribe((res) => {
            console.log(res);
            if (res["phone"]) {
                this.updatePatientProp("phone", res["phone"]);
            }
        });
    }

    verifyPhoneNumber() {
        let defaultWidth: string = "350px";
        const dialogRef = this.dialog.open(
            PhoneNumberVerificationDialogComponent,
            {
                width: defaultWidth,
                data: {
                    //cancelButton: true,
                },
            }
        );

        return dialogRef.afterClosed();
    }

    resetMedicalFile() {
        this.medicalFile = JSON.parse(localStorage.getItem("user"));

        console.log(this.medicalFile);
        this.medicalFile.dob = new Date(this.medicalFile.dob);
    }

    updateMedicalFile() {
        if (this.loading) return;
        if (
            !this.medicalFile.email ||
            !this.medicalFile.name ||
            !this.medicalFile.height ||
            !this.medicalFile.width ||
            !this.medicalFile.phone ||
            !this.medicalFile.dob   
        ) {
            this.modal.openValidationDialog(
                "warning",
                "Error",
                "Please fill required fields"
            );
            return;
        }

        this.loading = true;

        let dob = this.datePipe.transform(this.medicalFile.dob, "dd/MM/yyyy");

        console.log(this.medicalFile);

        this.apiService.updateProfile(this.medicalFile, dob).subscribe(
            (res) => {
                this.loading = false;
                console.log(res);
                if (res["status"] == false) {
                    this.modal.snackMessage(res["error"]);
                    return;
                } else {
                    this.medicalFile = res["data"];
                    localStorage.setItem("user", JSON.stringify(res["data"]));
                    this.modal.snackMessage("Profile updated successfully");
                }
            },
            (error) => {
                this.loading = false;
                this.modal.snackMessage(error["error"].error);
            }
        );
    }

    setNotificationPermission(e) {
        console.log(e);
    }

    setAutoRenewal(e) {
        console.log(e);
    }

    setLang(e) {
        console.log("e");
    }

    updatePatientProp(key, value) {
        console.log(key, value);
        let prop = {};
        prop[key] = value;
        console.log(prop[key]);
        if (!prop[key]) {
            this.modal.snackMessage(`Please fill valid ${key}`);
            return;
        }
        this.apiService.updatePatientProperty(prop).subscribe(
            (res) => {
                if (res["status"] == false) {
                    console.log("error");
                    this.modal.snackMessage(res["error"]);
                    return;
                } else {
                    console.log(res);
                    localStorage.setItem("user", JSON.stringify(res["data"]));
                    this.medicalFile = res["data"];
                    this.modal.snackMessage(`${key} changed successfully`);
                }
            },
            (error) => {
                this.modal.snackMessage(error["error"].error);
            }
        );
    }

    fillPatientSessions() {
        this.apiService.getPatientSessions().subscribe((res) => {
            console.log(res);
            this.upcomingSessions = res["data"]["upcoming"];
            this.pastSessions = res["data"]["past"];
            this.cancelledSessions = res["data"]["cancelled"];
        });
    }

    fillPatientConsultations() {
        this.apiService.getMyQuestions().subscribe((res) => {
            this.questions = res['data']['items']
        });
    }
}
