<app-header-two></app-header-two>

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                {{ "About Us" | translate }}
            </h2>
        </div>
        <div class="row">
            <div class="col-lg-12" style="margin-bottom: 30px">
                <img
                    src="assets/img/walter-otto-PT70CT6mATQ-unsplash.png"
                    width="100%"
                    style="height: 450px !important"
                />
            </div>
            <div class="col-lg-12" style="margin-bottom: 30px">
                <p class="vision-title">{{ "Our Vision" | translate }}</p>
                <p class="vision-message">
                    We aim to digitally make users' live easier by being able to
                    connect and communicate with doctors virtually.
                </p>
            </div>

            <div class="col-lg-12" style="margin-bottom: 30px">
                <p class="vision-title">{{ "Our Message" | translate }}</p>
                <p class="vision-message">
                    provide better health for the community and to improve their
                    daily lives.
                </p>
            </div>
            <div class="col-lg-12" style="margin-bottom: 30px">
                <p class="vision-title">{{ "Why Itammen" | translate }}</p>
                <p class="vision-message">
                    The platform covers several sections ranging from; Doctor
                    consultation, health library, news articles as well as
                    wellness initiatives for a healthier lifestyle. By providing
                    all of this in one place, we are more cost and time
                    efficient for the users.
                </p>
            </div>
        </div>
    </div>
</section>
