import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { User } from "../../../models/user.model";
import { ModalService } from "../../../services/modal.service";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { OTPDialogComponent } from "./otp-dialog/otp-dialog.component";
import {
    FormBuilder,
    FormGroup,
    ValidationErrors,
    Validators,
} from "@angular/forms";

@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
    loading: boolean = false;
    user: User = new User();
    emailForm!: FormGroup;
    phoneForm!: FormGroup;
    genders = ["male", "female", "other"];
    checked;
    isSubmitted = false;

    constructor(
        private authService: AuthService,
        private modal: ModalService,
        private fb: FormBuilder,

        private router: Router,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.emailForm = this.fb.group({
            email: ["", [Validators.required]],
            gender: [],
            name: [],
            dob: [],
            checked: ["", Validators.required],
        });
        this.phoneForm = this.fb.group({
            phone: ["", [Validators.required]],
            dob: [],
            gender: [],
            name: [],
            checked: ["", Validators.required],
        });
    }
    getphoneControlErrors(controlName: string): ValidationErrors | null {
        return this.phoneForm.controls[controlName].errors;
    }
    getEmailControlErrors(controlName: string): ValidationErrors | null {
        return this.emailForm.controls[controlName].errors;
    }

    signUp(type: string) {
        this.isSubmitted = true;
        let body = {};
        if (type === "email") {
            body = {
                is_email: 1, // if 0 => phone, 1=> email
                email: this.emailForm.value.email,
                name: this.emailForm.value.name,
                gender: this.emailForm.value.gender,
                dob: this.emailForm.value.dob,
            };
        } else {
            body = {
                is_email: 0, // if 0 => phone, 1=> email
                phone: this.phoneForm.value.phone,
                name: this.phoneForm.value.name,
                gender: this.phoneForm.value.gender,
                dob: this.phoneForm.value.dob,
            };
        }
        this.dialog.open(OTPDialogComponent, {
            panelClass: "confirm-dialog-panel",
            width: "30vw",
            disableClose: true,
            minWidth: "500px",
            data: {
                entity: type,
            },
        });

        this.loading = true;
        this.authService.register(body).subscribe(
            (res) => {
                this.loading = false;
                console.log(res);
                this.router.navigate(["/"]);
            },
            (error) => {
                this.loading = false;
                if (error.status === 404)
                    this.modal.snackMessage(
                        " Email is not registered or your account hasn't been activated yet."
                    );
                else {
                    this.modal.snackMessage(error["error"]["error"]);
                }
            }
        );
    }
}
