import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ValidationErrors } from "@angular/forms";

@Component({
    selector: "app-validation-errors",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./validation-errors.component.html",
    styleUrls: ["./validation-errors.component.scss"],
})
export class ValidationErrorsComponent {
    @Input() mismatchError = false;
    @Input() errors!: ValidationErrors | null;

    @Input() isSubmitted: boolean | undefined;

    ngOnInit() {
        if (this.isSubmitted === undefined) {
            throw new Error("isSubmitted input is required.");
        }

        if (this.errors === undefined) {
            throw new Error("errors input is required.");
        }
    }
}
