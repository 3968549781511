<app-header-two></app-header-two>

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6 text-left">
                <div class="partner-item">
                    <img [src]="prescription?.image?.url" alt="image" />
                </div>
            </div>

            <div class="col-lg-8 text-center" style="margin-top: 20px; margin-left: -50px;">
                <div class="col-flex">
                    <div style="flex: 3">
                        <h2 class="name">{{prescription?.title}}</h2>
                    </div>
                    <div style="flex: 5; margin-top: -15px; color: #565656;">
                        <p #description class="description" [innerHtml]="prescriptionDesc">
                          
                        </p>
                       
                    </div>

                    <div class="row">
                        <div class="col-lg-2 top-space" *ngFor="let tag of prescription.tags">
                            <a class="learn-more-btn">{{tag}} </a>
                        </div>
                       
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
