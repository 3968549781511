import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-health-library",
    templateUrl: "./health-library.component.html",
    styleUrls: ["./health-library.component.scss"],
})
export class HealthLibraryComponent implements OnInit {
    categories: any[] = [];

    constructor(
        private apiService: ApiService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.fillCategories();
        this.test();
    }

    ngOnInit(): void {}

    fillCategories() {
        this.apiService.getDiseaseCategories().subscribe((res) => {
            console.log(res);
            this.categories = res["data"];
            console.log(this.categories);
        });
    }

    test() {
        console.log("test");
    }

    navigateToViewDisease(obj) {
        console.log(obj);
        this.router.navigate(["/diseases", { id: obj.id }], {
            relativeTo: this.route,
        });
    }
}
