<app-header-two></app-header-two>

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Diseases <span class="questions-text">Questions</span></h2>
        </div>

        <div class="row">
            <div class="col-lg-2 sort-by">
                <span>Sort By :</span>
            </div>
            <div class="col-lg-2">
                <div class="select-box">
                    <select class="form-control">
                        <option>Newest</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="select-box">
                    <select class="form-control">
                        <option>Category</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="select-box">
                    <select class="form-control">
                        <option>Top Viewed</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="select-box">
                    <select class="form-control">
                        <option>Popular</option>
                    </select>
                </div>
            </div>

            <div class="col-lg-2"></div>
        </div>

        <br />
        <br />

        <div class="row">
            <div class="col-lg-12 top-space" *ngFor="let question of questions">
                <mat-card class="question-card">
                    <div class="row-flex">
                        <div
                            style="
                                flex: 1;
                                align-self: flex-start;
                                display: contents;
                            "
                        >
                            <img
                                width="100px"
                                src="assets/img/question-img.jpg"
                                style="border-radius: 50%"
                            />
                        </div>
                        <div class="col-flex">
                            <div style="flex: 3">
                                <h2 class="name">{{ question.title }} ?</h2>
                            </div>
                            <div style="flex: 5; margin-top: -15px">
                                <p class="description" *ngIf="question.answers.length">{{question.answers[0]}}</p>
                                <p class="description" *ngIf="!question.answers.length">Wait For Reply</p>
                            </div>
                           
                        </div>
                        <div style="flex: 5"></div>
                    </div>
                </mat-card>
            </div>
        </div>

        <br />
        <br />

        <!-- <div class="row justify-content-center pb-5">
            <div class="col-lg-6 pb-5">
                <div class="status-Contaier mb-5">
                    <span class="icon-container active"
                        ><i class="fa fa-check"></i>
                    </span>
                    <span class="icon-container active"
                        ><i class="fa fa-check"></i>
                    </span>
                    <span class="icon-container"
                        ><i class="fa fa-check"></i>
                    </span>
                </div>
            </div>
        </div> -->
    </div>
</section>
