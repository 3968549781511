import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { RegisterComponent } from "./components/pages/register/register.component";
import { NewsListComponent } from "./components/pages/news-list/news-list.component";
import { HealthLibraryComponent } from "./components/pages/health-library/health-library.component";
import { WellnessInitiativesListComponent } from "./components/pages/wellness-initiatives-list/wellness-initiatives-list.component";
import { HealthPrescriptionListComponent } from "./components/pages/health-prescription-list/health-prescription-list.component";
import { SingleNewComponent } from "./components/pages/single-new/single-new.component";
import { DiseaseComponent } from "./components/pages/disease/disease.component";
import { SingleWellnessInitiativesComponent } from "./components/pages/single-wellness-initiatives/single-wellness-initiatives.component";
import { SingleHealthPrescriptionComponent } from "./components/pages/single-health-prescription/single-health-prescription.component";
import { DiscoverQuestionsListComponent } from "./components/pages/discover-questions-list/discover-questions-list.component";
import { SubscribeComponent } from "./components/pages/subscribe/subscribe.component";
import { AskDoctorComponent } from "./components/pages/ask-doctor/ask-doctor.component";
import { PatientProfileComponent } from "./components/pages/patient-profile/patient-profile.component";
import { DoctorsListComponent } from "./components/pages/doctors-list/doctors-list.component";
import { DoctorProfileComponent } from "./components/pages/doctor-profile/doctor-profile.component";
import { AskSuccessfullyComponent } from "./components/pages/ask-successfully/ask-successfully.component";
import { SavedSuccessfullyComponent } from "./components/pages/saved-successfully/saved-successfully.component";
import { SubscribedSuccessfullyComponent } from "./components/pages/subscribed-successfully/subscribed-successfully.component";
import { SomethingWrongComponent } from "./components/pages/something-wrong/something-wrong.component";
import { DiseasesListComponent } from "./components/pages/diseases-list/diseases-list.component";
import { ContactUsComponent } from "./components/pages/contact-us/contact-us.component";
import { AboutUsComponent } from "./components/pages/about-us/about-us.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";

const routes: Routes = [
    { path: "", component: HomeOneComponent },

    { path: "error", component: ErrorComponent },
    { path: "login", component: LoginComponent },
    { path: "register", component: RegisterComponent },
    { path: "news-list", component: NewsListComponent },
    { path: "health-library", component: HealthLibraryComponent },
    {
        path: "wellness-initiatives",
        component: WellnessInitiativesListComponent,
    },
    { path: "health-prescription", component: HealthPrescriptionListComponent },
    { path: "single-new", component: SingleNewComponent },
    { path: "single-wellness", component: SingleWellnessInitiativesComponent },
    { path: "disease", component: DiseaseComponent },
    {
        path: "single-prescription",
        component: SingleHealthPrescriptionComponent,
    },
    { path: "discover-questions", component: DiscoverQuestionsListComponent },
    { path: "subscribe", component: SubscribeComponent },
    { path: "ask-doctor", component: AskDoctorComponent },
    { path: "patient-profile", component: PatientProfileComponent },
    { path: "doctor-list", component: DoctorsListComponent },
    { path: "ask-success", component: AskSuccessfullyComponent },
    { path: "saved-success", component: SavedSuccessfullyComponent },
    {
        path: "subscription-success",
        component: SubscribedSuccessfullyComponent,
    },
    { path: "wrong", component: SomethingWrongComponent },
    { path: "doctor-profile", component: DoctorProfileComponent },
    { path: "diseases", component: DiseasesListComponent },
    { path: "contact-us", component: ContactUsComponent },
    { path: "about-us", component: AboutUsComponent },
    { path: "privacy", component: PrivacyPolicyComponent },
    { path: "**", component: ErrorComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
